import React, { lazy, Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import { EngineLayout } from '../components';
import { Layout, Loader } from '../components/domain';

const Logout = lazy(async () => await import('../pages/Logout'));
const Error = lazy(async () => await import('../pages/Error'));
const Tests = lazy(async () => await import('../pages/Tests'));
const HealthCheckup = lazy(async () => await import('../pages/HealthCheckup'));
const TestLaunch = lazy(async () => await import('../pages/TestLaunch'));
const TestEngine = lazy(async () => await import('../pages/Testengine/Engine'));
const TestEngineValidate = lazy(
  async () => await import('../pages/Testengine/Validate'),
);
const Settings = lazy(async () => await import('../pages/Settings'));

const AppRouter = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<EngineLayout />}>
          <Route
            path="/test-engine"
            element={<TestEngine />} />
        </Route>
        <Route
          path="/test-engine/token/validate/:id"
          element={<TestEngineValidate />}
        />
        <Route element={<Layout />}>
          <Route
            path="/"
            element={<Tests />} />
          <Route
            path="/login"
            element={<Tests />} />
          <Route
            path="/tests"
            element={<Tests />} />
          <Route
            path="/test-launch"
            element={<TestLaunch />} />
          <Route
            path="/health-check"
            element={<HealthCheckup />} />
          <Route
            path="/settings"
            element={<Settings />} />
          <Route
            path="/logout"
            element={<Logout />} />
        </Route>
        <Route
          path="*"
          element={<Error />} />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
