import { validateEmail, validateMobileNo } from './utils';

export const validateLoginForm = values => {
  const newErrors = {};

  if (!values.loginId) {
    newErrors.loginId = 'Enter your loginId';
  }
  if (!values.password) {
    newErrors.password = 'Enter your password';
  }
  return newErrors;
};

export const validateUserForm = values => {
  const newErrors = {};

  if (!validateEmail(values.emailId)) {
    newErrors.emailId = 'Enter your valid email';
  }
  if (!validateMobileNo(values.mobileNumber)) {
    newErrors.mobileNumber = 'Enter your valid mobile number';
  }
  return newErrors;
};

export const validateRegisterForm = values => {
  const newErrors = {};

  if (!values.firstName) {
    newErrors.firstName = 'Enter your first name';
  }
  if (!values.lastName) {
    newErrors.lastName = 'Enter your last name';
  }
  if (!values.college) {
    newErrors.college = 'Select your college';
  }
  if (!values.department) {
    newErrors.department = 'Select your department';
  }
  if (!values.pursuingDegree) {
    newErrors.pursuingDegree = 'Select your pursuing degree';
  }
  if (!values.semester) {
    newErrors.semester = 'Select your semester';
  }
  if (!values.productId) {
    newErrors.productId = 'Select your product';
  }
  if (!values.section) {
    newErrors.section = 'Enter your section';
  }
  if (!values.enrollmentNumber) {
    newErrors.enrollmentNumber = 'Enter your enrollment number';
  }

  return newErrors;
};

export const validateForgotForm = values => {
  const newErrors = {};

  if (!values.loginId) {
    newErrors.loginId = 'Enter your loginId';
  }
  return newErrors;
};

export const validateResetPasswordForm = (values) => {
  const errors = {};

  if (!values.otp) {
    errors.otp = 'Enter the OTP';
  } else if (values.otp?.length !== 6) {
    errors.otp = 'OTP should be equal to 6 digits';
  }
  if (!values.password?.trim()) {
    errors.password = 'Enter the new password';
  }
  if (!values.cpassword?.trim()) {
    errors.cpassword = 'Enter the confirm password';
  }
  return errors;
};


export const validateTestCodeForm = values => {
  const newErrors = {};

  if (!values.testCode) {
    newErrors.testCode = 'Enter the valid group code';
  }

  return newErrors;
};

export const validateTestLaunchForm = values => {
  const newErrors = {};

  if (!values.testAccessCode) {
    newErrors.testAccessCode = 'Enter the valid code';
  }

  return newErrors;
};
