import React, { lazy, Suspense } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import { Loader } from '../components/domain';

const Login = lazy(async () => await import('../pages/Login'));
const Register = lazy(async () => await import('../pages/Register'));
const ForgotPassword = lazy(async () => await import('../pages/ForgotPassword'));

const AuthRouter = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/"
          element={<Login />} />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />} />
        <Route
          path="/login"
          element={<Login />} />
        <Route
          path="/register"
          element={<Register />} />
        <Route
          path="*"
          element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default AuthRouter;
