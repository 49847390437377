import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { validateLoginForm } from '../lib/formValidations';
import { AlertBox, Button } from '../components/domain';
import {
  InputField,
  PasswordInput,
} from '../components/domain/ReduxFormComponents';
import { connect } from 'react-redux';
import { removeError } from '../redux/actions/action-auth';
import { useNavigate } from 'react-router-dom';

const Form = ({ errorMsg, ...props }) => {
  const navigate = useNavigate();
  return (
    <form onSubmit={props.handleSubmit}>
      {errorMsg && <AlertBox show message={errorMsg} type="danger" />}
      <div className="mb-3">
        <label className="form-label" htmlFor="loginId">
          Login ID
        </label>
        <Field
          type="loginId"
          name="loginId"
          className="form-control"
          component={InputField}
          placeholder="Enter your login id"
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="password">
          Password
        </label>
        <Field
          type="password"
          name="password"
          className="form-control"
          component={PasswordInput}
          placeholder="Enter your password"
          autoComplete="current-password"
        />
      </div>
      <div className='d-flex justify-content-end'>
        {/* <p className='text-primary cursor-pointer' onClick={()=> navigate('/forgot-password')}>Forgot Password?</p> */}
        <p className='text-primary cursor-pointer' onClick={()=> {
          navigate('/register');
          props.removeError();
        }}>Register</p>
      </div>
      <div className="d-grid gap-2">
        <Button
          type="submit"
          className="btn btn-success rounded-5 login-btn"
          loading={props.loggingIn}
          loadingText="Logging In..."
          disabled={props.loggingIn}>
          Login
        </Button>
      </div>
    </form>
  );
};

const LoginForm = reduxForm({
  form: 'Login',
  validate: validateLoginForm,
})(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  removeError: PropTypes.func.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])])
};

const mapState = ({ auth }) => ({
  loggingIn: auth.loggingIn,
  errorMsg: auth.error,
});

const mapDispatchToProps = dispatch => ({
  removeError: () => dispatch(removeError()),
});

export default connect(mapState, mapDispatchToProps)(LoginForm);
