import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { AlertBox } from '../components/domain';
import { InputField, SelectField } from '../components/domain/ReduxFormComponents';
import { validateRegisterForm } from '../lib/formValidations';
import { removeError } from '../redux/actions/action-auth';

const Form = ({ errorMsg, ...props }) => {
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null);

  const colgBasedDept = useMemo(() => {
    return props.departments?.filter((dept) => selectedCollege === dept.college);
  }, [selectedCollege]);

  const semBasedProducts = useMemo(() => {
    return props.products?.filter((prod) => selectedSemester === prod.semester).sort((a, b) => a.name - b.name);
  }, [selectedSemester]);

  return (
    <form onSubmit={props.handleSubmit}>
      {errorMsg && <AlertBox
        show
        message={errorMsg}
        type="danger" />}
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="firstName">
          First Name
        </label>
        <Field
          type="text"
          name="firstName"
          className="form-control"
          component={InputField}
          placeholder="Enter your first name"
        />
      </div>
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="lastName">
          Last Name
        </label>
        <Field
          type="text"
          name="lastName"
          className="form-control"
          component={InputField}
          placeholder="Enter your last name"
        />
      </div>
      <div className="mb-3">
        <div className='d-flex justify-content-between'>
          <label
            className="form-label"
            htmlFor="emailId">
            Email
          </label>
          <p
            className='text-primary cursor-pointer'
            style={{ marginLeft: '10px', fontSize: '13px' }}
            onClick={() => {
              props.setShowRegister(false);
              props.setUserDetails({ emailId: '', mobileNumber: '' });
            }}>Change Email</p>
        </div>
        <Field
          type="emailId"
          name="emailId"
          className="form-control"
          component={InputField}
          placeholder="Enter your email id"
          disabled={props.initialValues?.emailId}
        />
      </div>
      <div className="mb-3">
        <div className='d-flex justify-content-between'>
          <label
            className="form-label"
            htmlFor="mobileNumber">
            Mobile Number
          </label>
          <p
            className='text-primary cursor-pointer'
            style={{ marginLeft: '10px', fontSize: '13px' }}
            onClick={() => {
              props.setShowRegister(false);
              props.setUserDetails({ emailId: '', mobileNumber: '' });
            }}>Change Mobile</p>
        </div>
        <Field
          type="mobileNumber"
          name="mobileNumber"
          className="form-control"
          component={InputField}
          placeholder="Enter your mobile number"
          disabled={props.initialValues?.mobileNumber}
        />
      </div>
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="college">
          College
        </label>
        <Field
          id="college"
          name="college"
          component={SelectField}
          className="form-control"
          onChange={(e) => setSelectedCollege(e.target.value)}>
          <option value="">Select College</option>
          {props.college?.map((col) => (
            <option
              value={col.id}
              key={col.id}>{col.name}</option>
          ))}
        </Field>
      </div>
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="pursuingDegree">
          Pursuing Degree
        </label>
        <Field
          id="pursuingDegree"
          name="pursuingDegree"
          component={SelectField}
          className="form-control"
        >
          <option value="">Select Degree</option>
          {props.pursuingDegree?.map((deg) => (
            <option
              value={deg.id}
              key={deg.id}>{deg.name}</option>
          ))}
        </Field>
      </div>
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="department">
          Department
        </label>
        <Field
          id="department"
          name="department"
          component={SelectField}
          className="form-control"
        >
          <option value="">Select Department</option>
          {colgBasedDept?.map((dep) => (
            <option
              value={dep.id}
              key={dep.id}>{dep.name}</option>
          ))}
        </Field>
      </div>
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="section">
          Section
        </label>
        <Field
          type="text"
          name="section"
          className="form-control"
          component={InputField}
          placeholder="Enter your section"
        />
      </div>
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="semester">
          Semester
        </label>
        <Field
          id="semester"
          name="semester"
          component={SelectField}
          className="form-control"
          onChange={(e) => setSelectedSemester(e.target.value)}
        >
          <option value="">Select semester</option>
          {props.semester?.map((sem) => (
            <option
              value={sem.id}
              key={sem.id}>{sem.name}</option>
          ))}
        </Field>
      </div>
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="productId">
          Product
        </label>
        <Field
          id="productId"
          name="productId"
          component={SelectField}
          className="form-control"
        >
          <option value="">Select Product</option>
          {semBasedProducts?.map((p) => (
            <option
              value={p.externalKeyId}
              key={p.id}>{p.name}</option>
          ))}
        </Field>
      </div>
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="enrollmentNumber">
          Enrollment Number
        </label>
        <Field
          type="text"
          name="enrollmentNumber"
          className="form-control"
          component={InputField}
          placeholder="Enter enrollment number"
        />
      </div>
    </form >
  );
};

const RegisterForm = reduxForm({
  form: 'Register',
  validate: validateRegisterForm,
})(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  pursuingDegree: PropTypes.array.isRequired,
  semester: PropTypes.array.isRequired,
  college: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  setShowRegister: PropTypes.func.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  removeError: PropTypes.func.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])])
};

const mapState = (state, ownProps) => ({
  initialValues: {
    ...ownProps.userDetails,
    ...state.auth.userDetails,
  },
  loading: state.auth.registerLoading,
  errorMsg: state.auth.error,
  products: state.auth.products,
  departments: state.auth.departments,
  pursuingDegree: state.auth.pursuingDegree,
  semester: state.auth.semester,
  college: state.auth.college,
});


const mapDispatchToProps = dispatch => ({
  removeError: () => dispatch(removeError()),
});

export default connect(mapState, mapDispatchToProps)(RegisterForm);
