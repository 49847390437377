import { updateState } from '../../lib/utils';
import { authActions } from '../actionTypes';

const initState = {
  loggingIn: false,
  validating: true,
  error: null,
  user: {},
  isLoggedIn: false,
  forgotSuccess: null,
  forgotFailed: null,
  forgotLoading: false,
  resetLoading: false,
  resetErrorMsg: null,
  resetSuccessMsg: null,
  validateLoading: false,
  registerLoading: false,
  products: [],
  department: [],
  pursuingDegree: [],
  engagementId: '',
  branchId: '',
  college: [],
  semester: [],
  opportunityObj: {},
  userDetails: {},
  refId: null
};

export default (state = initState, action) => {
  switch (action.type) {
    case authActions.AUTH_LOGIN:
      return updateState(state, { ...action.payload });

    case authActions.AUTH_VALIDATE_TOKEN:
      return updateState(state, { ...action.payload });

    case authActions.LOGGEDIN_STATUS:
      return updateState(state, { ...action.payload });

    case authActions.FORGOT_PASSWORD_CHANGES:
      return updateState(state, { ...action.payload, });

    case authActions.RESET_PASSWORD_CHANGES:
      return updateState(state, { ...action.payload, });

    case authActions.FETCH_DROP_DOWN_DATA:
      return updateState(state, { ...action.payload });
    case authActions.FETCH_DROP_DOWN_DATA_SUCCESS:
      return updateState(state, { ...action.payload });
    case authActions.FETCH_DROP_DOWN_DATA_FAILURE:
      return updateState(state, { ...action.payload });
    case authActions.AUTH_REGISTER_START:
      return updateState(state, { ...action.payload });
    case authActions.AUTH_REGISTER_SUCCESS:
      return updateState(state, { ...action.payload });
    case authActions.AUTH_REGISTER_FAILURE:
      return updateState(state, { ...action.payload });
    case authActions.AUTH_VALIDATE_START:
      return updateState(state, { ...action.payload });
    case authActions.AUTH_VALIDATE_SUCCESS:
      return updateState(state, { ...action.payload });
    case authActions.AUTH_VALIDATE_FAILURE:
      return updateState(state, { ...action.payload });
    case authActions.AUTH_REMOVE_ERROR:
      return updateState(state, { ...action.payload });

    default:
      return state;
  }
};
