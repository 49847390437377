const FETCH_COMPANY = 'FETCH_COMPANY';

export const companyActions = {
  FETCH_COMPANY,
};

const AUTH_LOGIN = 'AUTH_LOGIN';
const AUTH_VALIDATE_TOKEN = 'AUTH_VALIDATE_TOKEN';
const LOGGEDIN_STATUS = 'LOGGEDIN_STATUS';
const FORGOT_PASSWORD_CHANGES = 'FORGOT_PASSWORD_CHANGES';
const RESET_PASSWORD_CHANGES = 'RESET_PASSWORD_CHANGES';
const FETCH_DROP_DOWN_DATA = 'FETCH_DROP_DOWN_DATA';
const FETCH_DROP_DOWN_DATA_SUCCESS = 'FETCH_DROP_DOWN_DATA_SUCCESS';
const FETCH_DROP_DOWN_DATA_FAILURE = 'FETCH_DROP_DOWN_DATA_FAILURE';
const AUTH_REGISTER_START = 'AUTH_REGISTER_START';
const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
const AUTH_REGISTER_FAILURE = 'AUTH_REGISTER_FAILURE';
const AUTH_VALIDATE_START = 'AUTH_VALIDATE_START';
const AUTH_VALIDATE_SUCCESS = 'AUTH_VALIDATE_SUCCESS';
const AUTH_VALIDATE_FAILURE = 'AUTH_VALIDATE_FAILURE';
const AUTH_REMOVE_ERROR = 'AUTH_REMOVE_ERROR';

export const authActions = {
  AUTH_LOGIN,
  AUTH_VALIDATE_TOKEN,
  LOGGEDIN_STATUS,
  FORGOT_PASSWORD_CHANGES,
  RESET_PASSWORD_CHANGES,
  FETCH_DROP_DOWN_DATA,
  FETCH_DROP_DOWN_DATA_SUCCESS,
  FETCH_DROP_DOWN_DATA_FAILURE,
  AUTH_REGISTER_START,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_FAILURE,
  AUTH_VALIDATE_START,
  AUTH_VALIDATE_SUCCESS,
  AUTH_VALIDATE_FAILURE,
  AUTH_REMOVE_ERROR
};

const FETCH_TEST = 'FETCH_TEST';
const INIT_TEST = 'INIT_TEST';
const LAUNCH_TEST_BY_CODE = 'LAUNCH_TEST_BY_CODE';
const DELETE_TEST = 'DELETE_TEST';
const SET_DOWNLOAD_TOKENS = 'SET_DOWNLOAD_TOKENS';
const SET_TEST_ACCORDION = 'SET_TEST_ACCORDION';
const SET_LAUNCH_DETAILS = 'SET_LAUNCH_DETAILS';

export const testActions = {
  FETCH_TEST,
  INIT_TEST,
  LAUNCH_TEST_BY_CODE,
  DELETE_TEST,
  SET_DOWNLOAD_TOKENS,
  SET_TEST_ACCORDION,
  SET_LAUNCH_DETAILS
};

const SET_TEST_DATA = 'SET_TEST_DATA';
const SAVE_ANSWER = 'SAVE_ANSWER';
const SAVE_TRACKS = 'SAVE_TRACKS';
const LAUNCH_TEST = 'LAUNCH_TEST';
const SYNC_SUCCESS = 'SYNC_SUCCESS';
const SYNC_FAILED = 'SYNC_FAILED';
const SYNC_NOITEMS = 'SYNC_NOITEMS';
const END_TEST_START = 'END_TEST_START';
const END_TEST_SUCCESS = 'END_TEST_SUCCESS';
const END_TEST_FAILED = 'END_TEST_FAILED';
const TEST_COMPLETED = 'TEST_COMPLETED';
const TEST_DOWNLOAD = 'TEST_DOWNLOAD';
const SYNC_RECORDS = 'SYNC_RECORDS';
const START_TEST = 'START_TEST';
const SUBMIT_EVALUATION = 'SUBMIT_EVALUATION';

export const engineActions = {
  SET_TEST_DATA,
  SAVE_ANSWER,
  SAVE_TRACKS,
  LAUNCH_TEST,
  SYNC_SUCCESS,
  SYNC_FAILED,
  SYNC_NOITEMS,
  END_TEST_START,
  END_TEST_SUCCESS,
  END_TEST_FAILED,
  TEST_COMPLETED,
  TEST_DOWNLOAD,
  SYNC_RECORDS,
  START_TEST,
  SUBMIT_EVALUATION
};

const START_TIMER = 'START_TIMER';
const SET_TIMER = 'SET_TIMER';
const STOP_TIMER = 'STOP_TIMER';
const SET_COUNTDOWN_TIMER = 'SET_COUNTDOWN_TIMER';

export const timerActions = {
  START_TIMER,
  SET_TIMER,
  STOP_TIMER,
  SET_COUNTDOWN_TIMER,
};
