import { toast } from 'react-toastify';
import { reset } from 'redux-form';

import { COMPANY_CODE } from '../../constants/config';
import { checkOnline } from '../../hooks/useNetworkStatus';
import { hashPassword } from '../../lib/bcrypt';
import * as Cookies from '../../lib/Cookie';
import {
  checkAndInsertRecord,
  dbValidateToken,
  deleteDatabase,
  getLoginData,
} from '../../lib/dexie';
import Service from '../../lib/Service';
import { authActions } from '../actionTypes';

const storeAuthCookie = (token, id) => {
  Cookies.set('quizky_token', token);
  Cookies.set('quizky_user', id);
};

export const logIn = params => async (dispatch, getState) => {
  try {
    dispatch({
      type: authActions.AUTH_LOGIN,
      payload: { loggingIn: true },
    });

    dispatch({
      type: authActions.AUTH_LOGIN,
      payload: { loggingIn: true, user: {}, error: null },
    });

    const isOnline = checkOnline();
    let loginResponse = {};

    const {
      companyDetails: { companyCode },
    } = getState().company;

    if (isOnline) {
      loginResponse = await Service.POST({
        name: 'login/within-own-acc',
        payload: {
          ...params,
          companyCode: companyCode || COMPANY_CODE,
          app: 'offline-testengine',
        },
      }).then(res => {
        if (res.success) return res.data;
        throw new Error(res.message);
      });
    } else {
      let localUser = await getLoginData(params);
      if (!localUser.success) {
        throw new Error(localUser.message);
      }
      loginResponse = localUser.data;
    }
    dispatch(setIsLoggedIn(loginResponse.token));
    storeAuthCookie(loginResponse.token, loginResponse.userData.id);
    dispatch({
      type: authActions.AUTH_LOGIN,
      payload: {
        loggingIn: false,
        user: loginResponse.userData,
        token: loginResponse.token,
        error: null,
      },
    });

    const hashedPassword = await hashPassword(params.password);

    await checkAndInsertRecord('users', {
      token: loginResponse.token,
      userData: loginResponse.userData,
      _id: loginResponse.userData.id,
      loginId: params.loginId,
      password: hashedPassword,
    });
  } catch (error) {
    dispatch({
      type: authActions.AUTH_LOGIN,
      payload: { loggingIn: false, user: {}, error: error.message },
    });
  }
};

export const validateToken = () => async dispatch => {
  try {
    const token = Cookies.get('quizky_token');
    const userId = Cookies.get('quizky_user');
    const isOnline = checkOnline();

    if (!token) {
      throw new Error('UnAuthorized');
    }

    dispatch({
      type: authActions.AUTH_VALIDATE_TOKEN,
      payload: { validating: true, user: {} },
    });

    let resData = {};

    if (isOnline) {
      resData = await Service.GET({
        name: 'validate-auth-token',
        queryString: `token=${token}`,
      }).then(res => {
        if (res.success) {
          return res.data;
        }
        throw new Error(res.message);
      });
    } else {
      resData = await dbValidateToken('users', userId);
    }

    dispatch(setIsLoggedIn(resData.token));
    storeAuthCookie(resData.token, resData.userData.id);
    dispatch({
      type: authActions.AUTH_VALIDATE_TOKEN,
      payload: { validating: false, user: resData.userData },
    });
  } catch (error) {
    dispatch({
      type: authActions.AUTH_VALIDATE_TOKEN,
      payload: { validating: false, user: {} },
    });
    dispatch(setIsLoggedIn());
  }
};

export const logout = () => async dispatch => {
  await Cookies.eraseByName('quizky_token');
  await Cookies.eraseAllSync();
  // await deleteDatabase();
  dispatch(setIsLoggedIn());
  return true;
};

export const setIsLoggedIn = token => {
  const isLoggedIn = !!token;
  return { type: authActions.LOGGEDIN_STATUS, payload: { isLoggedIn } };
};

export const resetApplication = () => async dispatch => {
  await deleteDatabase();
  await dispatch(logout());
  window.location.reload();
};

export const forgotPassword = (params) => async (dispatch, getState) => {
  try {
    const isOnline = checkOnline();
    if (!isOnline) {
      const errorMessage = 'You appear to be offline. Please connect to the internet to proceed further.';
      dispatch({
        type: authActions.FORGOT_PASSWORD_CHANGES,
        payload: { forgotFailed: errorMessage, forgotLoading: false }
      });
      return;
    }
    const { companyDetails: { companyCode } } = getState().company;
    dispatch({
      type: authActions.FORGOT_PASSWORD_CHANGES,
      payload: { forgotLoading: true, resetPayload: {} }
    });
    const requestBody = {
      ...params,
      companyCode
    };

    const reponseData = await Service.POST({
      name: 'offline-test/reset-password',
      payload: requestBody,
    }).then((response) => {
      const resData = response;
      if (resData.success) {
        return resData;
      }
      throw new Error(resData.message);
    });
    dispatch(reset('ForgotPassword'));
    dispatch({
      type: authActions.FORGOT_PASSWORD_CHANGES,
      payload: {
        refId: reponseData.data.refId,
        forgotSuccess: reponseData.message,
        forgotLoading: false,
        resetPayload: requestBody
      }
    });
  } catch (error) {
    dispatch({
      type: authActions.FORGOT_PASSWORD_CHANGES,
      payload: { forgotFailed: error.message, forgotLoading: false, resetPayload: {} }
    });
  }
};

export const resetPassword = (params) => async (dispatch, getState) => {
  try {
    const isOnline = checkOnline();
    if (!isOnline) {
      const errorMessage = 'You appear to be offline. Please connect to the internet to proceed further.';
      dispatch({
        type: authActions.RESET_PASSWORD_CHANGES,
        payload: { resetErrorMsg: errorMessage, resetLoading: false }
      });
      return;
    }
    const { companyDetails: { companyCode } } = getState().company;
    const { refId } = getState().auth;

    dispatch({
      type: authActions.RESET_PASSWORD_CHANGES,
      payload: { resetLoading: true }
    });
    const requestBody = {
      password: params.password,
      otp: params.otp,
      token: refId,
      type: 'forget-password',
      companyCode
    };
    if (requestBody.password === params.cpassword) {
      const reponseData = await Service.PUT({
        name: 'offline-test/reset-password',
        payload: requestBody,
      }).then((response) => {
        const resData = response;
        if (resData.success) {
          return resData;
        }
        throw new Error(resData.message);
      });
      toast.success('Password updated successfully');
      dispatch(reset('resetPassword'));
      dispatch({
        type: authActions.RESET_PASSWORD_CHANGES,
        payload: { resetSuccessMsg: reponseData.success, resetLoading: false, forgotSuccess: null }
      });
    } else {
      throw new Error('Please enter same password');
    }
  } catch (error) {
    dispatch({
      type: authActions.RESET_PASSWORD_CHANGES,
      payload: { resetErrorMsg: error.message, resetLoading: false }
    });
  }
};

export const register = (data) => async (dispatch, getState) => {
  try {
    const isOnline = checkOnline();
    if (!isOnline) {
      const errorMessage = 'You appear to be offline. Please connect to the internet to proceed further.';
      dispatch({
        type: authActions.AUTH_REGISTER_FAILURE,
        payload: { error: errorMessage }
      });
      return;
    }
    dispatch({
      type: authActions.AUTH_REGISTER_START,
      payload: { registerLoading: true }
    });
    const { companyDetails: { companyCode } } = getState().company;
    const { engagementId, branchId, opportunityObj, userDetails } = getState().auth;

    const requestBody = {
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      emailId: data.emailId || '',
      mobileNumber: data.mobileNumber || '',
      college: data.college || '',
      department: data.department || '',
      semester: data.semester || '',
      section: data.section || '',
      pursuingDegree: data.pursuingDegree || '',
      enrollmentNumber: data.enrollmentNumber || '',
      engagementId,
      branchId: userDetails.branchId || branchId,
      companyCode,
      opportunityObj: {
        ...opportunityObj,
        productIds: data.productId || '',
      },
      studentExternalKeyId: userDetails.studentExternalKeyId,
      newUser: userDetails.newUser || false,
      type: 'register'
    };

    const reponseData = await Service.POST({
      name: 'offline-test/register',
      payload: requestBody,
    }).then((response) => {
      const resData = response;
      if (resData.success) {
        return resData;
      }
      throw new Error(resData.message);
    });

    if (reponseData.success) {
      toast.success(reponseData.message, { autoClose: 10000 });
    }
    dispatch({
      type: authActions.AUTH_REGISTER_SUCCESS,
      payload: { registerLoading: false }
    });
    return reponseData;
  } catch (error) {
    dispatch({
      type: authActions.AUTH_REGISTER_FAILURE,
      payload: { error: error.message }
    });
  }
};

export const validateUser = (data) => async (dispatch, getState) => {
  try {
    const isOnline = checkOnline();
    if (!isOnline) {
      const errorMessage = 'You appear to be offline. Please connect to the internet to proceed further.';
      dispatch({
        type: authActions.AUTH_VALIDATE_FAILURE,
        payload: { error: errorMessage }
      });
      return;
    }

    dispatch({
      type: authActions.AUTH_VALIDATE_START,
      payload: { validateLoading: true }
    });

    const { companyDetails: { companyCode } } = getState().company;

    const reponseData = await Service.POST({
      name: 'offline-test/validate-user',
      payload: {
        ...data,
        type: 'validate-user',
        companyCode
      },
    }).then((response) => {
      const resData = response;
      if (resData.success) {
        return resData;
      }
      throw new Error(resData.message);
    });

    dispatch({
      type: authActions.AUTH_VALIDATE_SUCCESS,
      payload: { userDetails: reponseData.data, validateLoading: false }
    });

    return reponseData;
  } catch (error) {
    dispatch({
      type: authActions.AUTH_VALIDATE_FAILURE,
      payload: { error: error.message }
    });
  }
};

export const dropdownData = () => async (dispatch, getState) => {
  try {

    dispatch({
      type: authActions.FETCH_DROP_DOWN_DATA,
    });
    const { companyDetails: { companyCode } } = getState().company;

    const isOnline = checkOnline();

    let resData = {};

    if (isOnline) {
      resData = await Service.GET({
        name: 'offline-test/list',
        queryString: `companyCode=${companyCode}`,
      }).then(res => {
        if (res.success) {
          return res.data;
        }
        throw new Error(res.message);
      });
    }

    dispatch({
      type: authActions.FETCH_DROP_DOWN_DATA_SUCCESS,
      payload: { ...resData }
    });
  } catch (e) {
    dispatch({
      type: authActions.FETCH_DROP_DOWN_DATA_FAILURE,
      payload: { fetchErrror: e }
    });
  }
};

export const removeError = () => async dispatch => {
  dispatch({
    type: authActions.AUTH_REMOVE_ERROR,
    payload: { error: null }
  });
};
