import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import { AlertBox, Button } from '../components/domain';
import { InputField } from '../components/domain/ReduxFormComponents';
import { validateUserForm } from '../lib/formValidations';
import { removeError } from '../redux/actions/action-auth';

const Form = ({ errorMsg, ...props }) => {
  const navigate = useNavigate();
  return (
    <form onSubmit={props.handleSubmit}>
      {errorMsg && <AlertBox
        show
        message={errorMsg}
        type="danger" />}
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="emailId">
          Email
        </label>
        <Field
          type="emailId"
          name="emailId"
          className="form-control"
          component={InputField}
          placeholder="Enter your email id"
        />
      </div>
      <div className="mb-3">
        <label
          className="form-label"
          htmlFor="mobileNumber">
          Mobile Number
        </label>
        <Field
          type="mobileNumber"
          name="mobileNumber"
          className="form-control"
          component={InputField}
          placeholder="Enter your mobile number"
        />
      </div>
      <div className='d-flex'>
        <span>Already Registered?</span>
        <p
          className='text-primary cursor-pointer font-weight-bold text-decoration-underline'
          style={{ marginLeft: '10px' }}
          onClick={() => {
            navigate('/');
            props.removeError();
          }}>Login</p>
      </div>
      <div className="d-grid gap-2">
        <Button
          type="submit"
          className="btn btn-success rounded-5 login-btn"
          loading={props.loading}
          loadingText="Please wait..."
          disabled={props.loading}>
          Register
        </Button>
      </div>
    </form >
  );
};

const ValidateForm = reduxForm({
  form: 'Validate',
  validate: validateUserForm,
})(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  pursuingDegree: PropTypes.array.isRequired,
  semester: PropTypes.array.isRequired,
  college: PropTypes.array.isRequired,
  removeError: PropTypes.func.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])])
};

const mapState = ({ auth }) => ({
  errorMsg: auth.error,
  products: auth.products,
  departments: auth.departments,
  pursuingDegree: auth.pursuingDegree,
  semester: auth.semester,
  college: auth.college,
  loading: auth.validateLoading,
});

const mapDispatchToProps = dispatch => ({
  removeError: () => dispatch(removeError()),
});

export default connect(mapState, mapDispatchToProps)(ValidateForm);
